<template>
  <div class="page">
      <van-nav-bar title="商品分享" left-arrow @click-left="$goBack()"/>
      <div class="main">
        <div class="data">
          <div class="tip">
              <img src="../../assets/GeImg/money_icon.png" alt=""> 
              <span>预估佣金￥{{data.makeup}},分享后不要忘记粘贴分享文案!</span>
          </div>
          <div class="box">
            <div class="title">


                  <div class="left"> 分享图片 </div>
                   
                  <div style="flex:1;margin-left:10px;color:#999;font-size:12px;">已选择{{imgs.filter(item => item.isCheck).length}}张图片</div>

                  <!-- <div class="btn" style="width:50px;" @click="imgs.forEach(item => item.isCheck = true)"><span>全选</span></div>
                  <div class="btn" style="width:70px;" @click="downLoad"> <span>保存图片</span></div> -->
            </div>
            <div class="imgs"  v-if="imgs.length">
             
              <div v-for="item ,index in imgs" :key="item.url"  @click="item.isCheck = !item.isCheck">
                 <img :src="item.url" class="img">
                 <img src="../../assets/img/checkout.png" alt="" class="icon" v-show="!item.isCheck">
                 <img src="../../assets/maker/check_in.png" alt="" class="icon" v-show="item.isCheck">
                 <p v-show="index == 0">二维码推广图</p>
              </div>

            </div>
          </div>
          <div class="box">
            <div class="title">


                  <div class="left"> 分享文案 </div>
        
                  <div style="flex:1;"></div>

                  <!-- <div class="btn" style="width:80px;"><span>仅复制淘口令</span> </div> -->
                  
            </div>
              <div class="wrap" ref="box" v-if="data" >
                {{data.title}}<br/>
                [在售价]{{data.price}}<br/>
                [券后价]{{data.discount_price}}<br/>
               {{ data.recommend}}
                

              </div>
              <div class="list" v-for=" item ,index in shareType" :key="index">
                  <div class="item"  v-show="item.isCheck">
                    <div v-if=" index&& shareType[index-1].isCheck || (shareType[0].isCheck && index == 2) ">---------------</div>
                  <span>{{item.content}}</span>
                  </div>

              </div>

               
          </div>
           <div class="tip">

              <div v-for="item , index in shareType" :key="item.content + index" @click="item.isCheck = !item.isCheck"> 
                    <img src="../../assets/img/checkout.png" v-show="!item.isCheck" alt="" class="icon">
                    <img src="../../assets/maker/check_in.png" v-show="item.isCheck" alt="" class="icon">
                   
                    <span style="width:100%;" >{{item.title}}</span>
              </div>
              

              
          </div>
        </div>
        
      </div>
      <div class="footer">
        <div class="title">分享至图文</div>
        <div class="share">

          <div class="icons">


            <div>
                  <img src="../../../public/img/weixin.png" alt="" @click="share(0)">
                  <span>微信</span>
            </div>

            <div>
                  <img src="../../../public/img/pengyouquan.png" alt="" @click="share(1)">
                  <span>朋友圈</span>
            </div>

            <div>
                  <img src="../../assets/img/QQ2.png" alt="" @click="share(2)">
                  <span>QQ</span>
            </div>


          </div>
              
        </div>
      </div>
  </div>
</template>

<script>
import { getGoodDetail} from '@/api/goods/goods'
  export default {
    data(){ 

        return{ 

          imgs : { },

          shareType: [],

          data:null

        }

    },

    mounted(){ 
      // this.$store.commit('setSession','14a23831-9358-4e97-915c-2b1959390fea')
        this.getUser().then( () => { 

           getGoodDetail({ itemId:this.$route.query.Id,platformId:this.$route.query.platformId}).then(res => { 



          if( res.data.code == 0 ){ 
                this.data = res.data.data.data
                this.imgs = (res.data.data.data.images).map(item => ({ isCheck:false,url:item}))
                this.shareType.push({ 
                  title:'淘口令',
                  isCheck:true,
                  content:'【淘口令】: ' +this.data.tkl
                })

                 
                this.shareType.push({ 
                  title:'邀请码',
                  isCheck:false,
                  content:'【邀请码】: '+ this.$store.state.userDate.inviteCode
                })

                 this.shareType.push({ 
                  title:'短链接',
                  isCheck:false,
                  content:`【短链接】：${this.data.coupon_click_url}`.trim()
                })
              
             
             
           
          }else{ 
            this.$toast(res.data.msg)
          }


        })


        })
       

    },
    methods:{ 

      share(type){ 
       
             let content = this.shareType.filter(item => item.isCheck)
            
            if(content.length==0){ 
              this.$toast('请选择分享内容选项')
              return
            }
          let imageList = this.imgs.filter( item => item.isCheck ).map(item => item.url)

          
         
  console.log(imageList);
                let data = { 
                  content:this.data.title + `\r\n [在售价]${this.data.price}\r\n[券后价]${this.data.discount_price}\r\n ` +  content.map(item => item.content).join('\r\n-----------------------------\r\n') ,
                  platformId: type,
                  title:'' ,
                  openUrl:'',
                  picUrl : '',
                  imageList
                }


                try{ 
                        window.webkit.messageHandlers.share.postMessage(JSON.stringify(data));
                }catch{ 

                  window.android.share(
                              data.platformId,
                              "3",
                              data.title,
                              data.content,
                              data.openUrl,
                              data.picUrl,
                              imageList
                  );
  
              }
        },
        // downLoad(){ 

        //     this.imgs.forEach(item => { 

        //         var image = new Image();
        //         // 解决跨域 Canvas 污染问题
        //         image.setAttribute("crossOrigin", "anonymous");
         //         image.src = item.url

        //         image.onload = function() {
        //           var canvas = document.createElement("canvas");
        //           canvas.width = image.width;
        //           canvas.height = image.height;
        //           var context = canvas.getContext("2d");
        //           context.drawImage(image, 0, 0, image.width, image.height);
        //           var url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
        //           var a = document.createElement("a"); // 生成一个a元素
        //           var event = new MouseEvent("click"); // 创建一个单击事件
        //           a.download = name || "photo"; // 设置图片名称
        //           a.href = url; // 将生成的URL设置为a.href属性
        //           a.dispatchEvent(event); // 触发a的单击事件
        //         };
       

        //     })
           

        // },
    }
  }
</script>

<style lang="less" scoped>

.page{ 
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  font-family: PingFangSC-Medium, PingFang SC;
  .main{ 
    flex: 1;
    background-color: #f6f6f6;
    padding:0 16px;
    overflow-y: auto;
    .data{
      padding: 10px 0;
        color: #333;
        font-size: 14px;
        .icon{ 
          width:14px ;
          height: 14px;
        }
      .tip{ 
        background-color: white;
        border-radius: 19px;
        height: 34px;
        display: flex;
        align-items: center;
        line-height: 20px;
        padding-left: 10px;
       
        
        div{ 
          display: flex;
          align-items: center;
           margin-right: 20px;
          
        }

        img{ 
          height: 14px;
          width: 14px;
          margin-right: 10px;
        }
      }
      .box{ 
        background-color: white;
        border-radius: 10px;
        margin:10px 0;
        padding: 10px;
        box-sizing: border-box;
        .title{ 
          display: flex;
          align-items: center;
          padding-bottom: 9px;
         .btn{ 
           color: white;
           background: linear-gradient(90deg, #FF7200 0%, #FF3C00 100%);
           border-radius: 17px;
           height: 22px;
           margin-left: 10px;
           display: flex;
           align-items: center;
           justify-content: center;
           font-size: 12px;
           span{ 
             transform: scale(0.92);
           }
           
           
         }

          
         
        }
        .imgs::-webkit-scrollbar{ 
          display: none;
        }
        .imgs{ 
            overflow-x: auto;
            display: flex;
           
            div{ 
               flex-shrink: 0;
              height: 92px;
              width: 92px;
              border-radius: 10px;
              overflow: hidden;
              position: relative;
              margin-right: 10px;
              .img{ 
                height: 100%;
                width: 100%;
              }
              .icon{ 
                position: absolute;
                top: 5px;
                right: 5px;
              }
              p{ 
               background-color: black;
               color: white;
               opacity: 0.6;
               position: absolute;
               bottom: 0;
               right: 0;
               width: 100%;
               display: flex;
               align-items: center;
               justify-content: center;
               font-size: 12px;
               height: 22px;
               
              }
            }
          }
          .wrap{ 
            background-color: #f6f6f6;
            border-radius: 5px;
            padding: 5px 10px;
            font-size: 12px;
            text-align: left;
            line-height: 25px;
            margin-bottom: 10px;
           
          }
          .list{ 
              .item{ 
                display: inline-block;
                box-sizing: border-box;
                width: 100%;
                white-space:pre-line ;
                word-wrap: break-word;
               
               
               
               
              }
          }
      }
     
    }
  }
   .footer{ 
     background-color: white;
     padding: 15px 16px 0;
     display: flex;
     flex-direction: column;
     align-items: center;
     .title{ 
       font-size: 17px;
       color: #333;
     }
     .share{ 
       margin-top: 15px;
       font-size: 12px;
       width: 100%;
       .icons{ 
         padding-bottom: constant(safe-area-inset-bottom);
         padding-bottom: env(safe-area-inset-bottom);
         display: flex;
         width: 100%;
          justify-content: space-around;
         div{ 
             width: 100%;
           display: flex;
           align-items: center;
           flex-direction: column;
          
           height:56px ;
           width: 76px;
           img{ 
             height: 33px;
             width: 33px;
            
           }
         }
       }
     }
   }
}
</style>